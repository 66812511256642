import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StyledHero from '../components/StyledHero';
import styles from '../css/404.module.css';

const NotFoundPage = ({ data }) => {
  const { page_title, header, content } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={page_title} />
      <StyledHero img={header.image.childImageSharp.fluid} />
      <div className={styles.container}>
        <div className={styles.message}>
          <h1>{content[0].title}</h1>
          <h2>{content[0].subtitle}</h2>
          <Link to={content[0].cta.url} className="btn-primary">
            {content[0].cta.text}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        page_title
        header {
          image {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          banner_main
          banner_sub
          cta_1 {
            text
            url
          }
          cta_2 {
            text
            url
          }
        }
        content {
          title
          subtitle
          text
          cta {
            text
            url
          }
        }
      }
    }
  }
`;
